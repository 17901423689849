<template>
  <div v-if="!isMobile" class="board-list-container">
    <!-- list-top -->
    <div class="list-top">
      <div class="top-column">
        <p class="title text-muted">총 {{ reports.length }}건</p>
      </div>
      <div v-if="myReport.lrnTrgtGrpDtlSn > 0 && !myReport.selfIntro" class="top-column">
        <button class="kb-btn kb-btn-primary" type="button" @click="goWrite"><span class="text">보고서작성</span></button>
      </div>
    </div>
    <!-- board-top -->
    <ul v-if="reports.length > 0" class="board-list divider-list">
      <!-- board-item -->
      <li v-for="(report, index) in reports" class="board-item divider-item" :key="index">
        <div class="item-card">
          <div class="card-content">
            <div class="content-title">
              <h4 class="title">
                <router-link :to="{name: 'MentoringView', params: {targetSn: report.lrnTrgtGrpDtlSn}, query: currentQuery}" class="title-link">종합활동보고서</router-link>
              </h4>
            </div>
            <div class="content-meta">
              <div class="meta"><span class="meta-text">{{ timestampToDateFormat(report.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></div>
              <div class="meta"><span class="meta-text text-muted">{{ mentoringDivText(targetItem.learners, report.lrnerId) }} ・ {{ report.lrnerNm }}</span></div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div v-else class="search-container">
      <div class="result-empty">
        <img src="../../../assets/lxp/images/common/img_empty.png" alt="">해
        <p class="text">종합활동보고서 정보가 없습니다.</p>
      </div>
    </div>
  </div>
  <div v-else class="notice-list-container">
    <!-- list-top -->
    <div class="list-top status">
      <div class="top-column">
        <p class="text text-muted">총 {{ reports.length }}건</p>
      </div>
    </div>
    <div class="board-list-container">
      <ul v-if="reports.length > 0" class="board-list">
        <li v-for="(report, index) in reports" class="board-list-item" :key="index">
          <router-link :to="{name: 'MentoringView', params: {targetSn: report.lrnTrgtGrpDtlSn}, query: currentQuery}" class="board-link"></router-link>
          <div class="list-content">
            <div class="list-content-title">
              <h5 class="title">
                <span class="text text-truncate">종합활동보고서</span>
              </h5>
            </div>
            <div class="list-meta-content">
              <span class="text">{{ timestampToDateFormat(report.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span>
              <span class="text">{{ mentoringDivText(targetItem.learners, report.lrnerId) }} ・ {{ report.lrnerNm }}</span>
            </div>
          </div>

        </li>
      </ul>
      <div v-else class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">종합활동보고서 정보가 없습니다.</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {timestampToDateFormat} from '@/assets/js/util';
import {computed} from 'vue';
import {mentoringDivText} from '@/assets/js/modules/course/mentoring-common';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'MentoringReport',
  components: {
  },
  props: {
    targetItem: Object
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const currentQuery = computed(() => route.query);

    const myReport = computed(() => {
      const target = props.targetItem.learners.find(x => x.lrnerId === session.value.lrnerId);
      return target || {lrnTrgtGrpDtlSn: 0}
    });

    const reports = computed(() => {
      return props.targetItem.learners.filter(x => x.lrnerId === session.value.lrnerId).filter(y => y.selfIntro);
    });

    const goWrite = () => {
      if(myReport.value.lrnTrgtGrpDtlSn > 0){
        router.push({name: 'MentoringWrite', params: {
            distCrseSn: props.targetItem.distCrseSn,
            targetSn: myReport.value.lrnTrgtGrpDtlSn
        }, query: currentQuery.value});
      }
    }

    return {
      currentQuery,
      reports,
      myReport,

      goWrite,
      mentoringDivText,
      timestampToDateFormat,
      isMobile: navigationUtils.any(),

    }
  }
};
</script>
