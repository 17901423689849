<template>
  <main class="kb-main" id="kb-sdl-mento">
    <!-- main-header -->
    <MentoringHeader
        v-if="isReady && !isNone"
        :is-mentor="isMentor"
        :items="items"
        :current-index="currentIndex"
        title="KB 멘토링"
        div="Mentoring"
        :links="[{key: 'main', name: '멘토링 정보'},{key: 'board', name: '멘토링 일지'},{key: 'report', name: '종합활동보고'}]"
        :breadcrumbs="['홈', '자기주도학습', 'KB 멘토링']"
        :target-item="targetItem"
        @changeMentee="changeMentee"
    />
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- sdl-info-container -->
      <template v-if="isReady">
        <div v-if="isNone" class="search-container">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">멘토링 정보가 없습니다.</p>
          </div>
        </div>
        <MentoringMeta
            v-else-if="view === 'main'"
            :mentor="mentor"
            :mentee="mentee"
        />
        <MentoringBoard
            v-else-if="view === 'board'"
            :target-item="targetItem"
            title="멘토링 일지"
            write="일지작성"
        />
        <MentoringReport
            v-else-if="view === 'report'"
            :target-item="targetItem"
        />
      </template>


    </div>
    <!-- //main-content -->
  </main>
</template>
<script>

import MentoringHeader from '@/components/prep/mentoring/MentoringHeader';
import {useStore} from 'vuex';
import {ACT_GET_CRSE_DIST_MENTORING} from '@/store/modules/course/course';
import {getItem, lengthCheck} from '@/assets/js/util';
import {computed, ref, watch} from 'vue';
import {grpRoleCdDcds} from '@/assets/js/modules/course/mentoring-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import MentoringMeta from '@/components/prep/mentoring/MentoringMeta';
import {useRoute, useRouter} from 'vue-router';
import MentoringBoard from '@/components/prep/mentoring/MentoringBoard';
import MentoringReport from '@/components/prep/mentoring/MentoringReport';

export default {
  name: 'MentoringMain',
  components: {MentoringReport, MentoringBoard, MentoringMeta, MentoringHeader},
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showLoading, hideLoading} = useAlert();
    const view = computed(() => route.params.view || 'main');

    const isMentor = ref(false);
    const isReady = ref(false);
    const isNone = ref(false);

    const currentIndex = ref(-1);
    const items = ref([]);

    const targetItem = computed(() => {
      if(currentIndex.value > -1 && items.value[currentIndex.value]){
        return items.value[currentIndex.value];
      }
      return {
        distCrseSn: 0,
        learners: []
      }
    });

    const mentor = computed(() => {
      if(targetItem.value.distCrseSn > 0){
        return targetItem.value.learners.find(x => x.grpRoleCdDcd === grpRoleCdDcds.leader) || {lrnTrgtGrpDtlSn: 0};
      }
      return {lrnTrgtGrpDtlSn: 0};
    })

    const mentee = computed(() => {
      if(targetItem.value.distCrseSn > 0){
        return targetItem.value.learners.find(x => x.grpRoleCdDcd === grpRoleCdDcds.learner) || {lrnTrgtGrpDtlSn: 0};
      }
      return {lrnTrgtGrpDtlSn: 0};
    });

    const distCrseSn = computed(() => route.query.distCrseSn ? parseInt(route.query.distCrseSn) : 0);

    const changeMentee = (index) => {
      router.push({query: {distCrseSn : items.value[index].distCrseSn}});
    }

    const getMentoringIndex = () => {
      const initIndex = items.value.map(x => x.distCrseSn).indexOf(distCrseSn.value);
      return initIndex > -1 ? initIndex : 0;
    }

    const getMentoring = () => {
      store.dispatch(`course/${ACT_GET_CRSE_DIST_MENTORING}`, {splCrseTyCdDcd: '2066005'}).then(res => {
        if(lengthCheck(res)) {
          const item = getItem(res);
          if(item.mentorings.length > 0) {
            isMentor.value = item.mentor;
            items.value = item.mentorings;
            currentIndex.value = getMentoringIndex();
          }else{
            isNone.value = true;
          }
        }
        isReady.value = true;
        hideLoading();
      }).catch(e => {
        console.error(e);
        hideLoading();
      });
    }

    showLoading();
    getMentoring();

    watch(() => route.query.distCrseSn, () => {
      if(route.name.indexOf('Mentoring') === 0) {
        currentIndex.value = getMentoringIndex();
      }
    });

    return {
      view,
      isReady,
      isNone,
      isMentor,
      items,
      currentIndex,
      targetItem,
      mentor,
      mentee,
      changeMentee,
    }
  }
};
</script>
